<template>
  <div id="main">
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <div class="container my-5">
      <div class="row">
        <div class="col-12">
          <h1 class="mb-4">{{locale[lang].APROPOS.TITLE}}</h1>
          <p v-for="(text, key) in locale[lang].APROPOS.CONTENT_1" :key="key" v-html="text">
          </p>
          <h2 class="my-5">{{locale[lang].APROPOS.SUBTITLE}}</h2>
          <p v-for="(text, key) in locale[lang].APROPOS.CONTENT_2" :key="key+3">
            {{ text }}
          </p>
          <div class="row justify-content-center">
            <div class="col-12">
              <img class="img-fluid my-5"
                src="https://www.preparationphysiquefootball.com/images/thibaud-préparateur-physique.jpg"
                alt="thibaud" />
              <h3>Thibaud</h3>
              <h5>{{locale[lang].APROPOS.END}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import NavBar from "@/components/essential/NavBar.vue";
  export default {
    components: {
      NavBar,
    },
  };
</script>

<style scoped>
  #nav-container {
    background-image: none;
    height: auto;
  }

  #nav-container>>>#logo {
    display: none;
  }

  .container {
    background-color: transparent !important;
  }

  #main {
    padding: 0;
    background-image: url("../assets/Images/Blog/Enmascarar grupo 134.png"),
      url("../assets/Images/Background/Grupo 736.png"),
      url("../assets/Images/Background/Trazado 1903.png"),
      url("../assets/Images/Background/Grupo 685.png");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 100% 65%, 50% 100%, 0% 35%, 92% 10%;
    padding-bottom: 10%;
  }

  * {
    color: #0f1010;
  }

  h1 {
    text-align: left;
    font-size: 3rem;
    font-weight: 700;
  }

  p {
    text-align: left;
    font-size: 1.15rem;
    font-weight: 500;
  }

  h2 {
    text-align: left;
    text-decoration: underline;
  }

  img {
    border-radius: 16px;
    max-height: 400px;
  }

  @media screen and (max-width: 991px) {
    h1 {
      font-size: 2.5rem;
    }

    #main {
      background-size: 40%, 60%, 40%, 40%;
    }
  }
</style>