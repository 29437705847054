<template>
  <div>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <div class="container-fluid" id="shop">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <div class="Card">
                <div>
                  <h1>
                    {{locale[lang].SHOP.TITLE}}
                  </h1>
                </div>
                <img class="img-fluid" src="@/assets/Images/Cart.png" alt="message" />
                <div class="title">
                  <h1>{{locale[lang].SHOP.SUBTITLE}}</h1>
                </div>
                <div class="text">
                  <h5 v-html="locale[lang].SHOP.CONTENT.TEXT_1">
                  </h5>
                  <h5 v-html="locale[lang].SHOP.CONTENT.TEXT_2">
                  </h5>
                  <h5 v-html="locale[lang].SHOP.CONTENT.TEXT_3">
                  </h5>
                  <p v-html="locale[lang].SHOP.CONTENT.TEXT_4">
                  </p>
                </div>

                <div class="button-shop">
                  <button class="btn btn-sc mt-5" @click="toShop">
                    {{locale[lang].SHOP.BUTTON}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" id="footer-container" >
      <Footer />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/essential/NavBar.vue";
import Footer from "@/components/essential/Footer.vue";
export default {
  components: {
    NavBar,
    Footer,
  },
  methods: {
    toShop() {
      window.open(this.locale[this.lang].SHOP.LINK, "_blank");
    },
  },
};
</script>

<style scoped>
#shop.container-fluid {
  padding: 80px 15%;
  background-image: url("../assets/Images/Background/Trazado 1903.png"),
    url("../assets/Images/Background/Grupo 1025.png"),
    url("../assets/Images/Background/Grupo 1027.png");
  background-repeat: no-repeat;
  background-size: auto, 276px 242px, auto;
  background-position: 1% 85%, 95% 95%, 5% 5%;
}

.Card {
  background: #0f1010 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #93939340;
  border-radius: 30px;
  opacity: 1;
  padding: 43px 60px;
}

.title {
  position: relative;
  margin-bottom: 30px;
}

.title::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 61px;
  left: 0;
  right: 0;
  bottom: -25%;
  margin: auto;
}

.Card h1 {
  text-align: center;
  font: normal normal bold 38px/50px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.Card h5 {
  text-align: center;
  font: normal normal 500 20px/32px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 45px;
  opacity: 1;
}

.Card p {
  text-align: center;
  font: normal normal 500 16px/26px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.fa-lock {
  color: #ff0313;
  margin-right: 10px;
}

.btn-sc {
  text-align: center;
  font: normal normal bold 22px/27px Montserrat;
  color: #ffffff;
  background-image: url("../assets/Images/Background/ball.png");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-color: transparent;
  border: 1.7px solid #ffffff !important;
  border-radius: 15px;
  padding: 24px 0;
  width: 100%;
  margin-bottom: 45px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-size: 95px 80px;
  box-shadow: 0px 3px 50px #a7a5a54d;
  margin-bottom: 50px;
}

.btn-sc:hover {
  background-color: rgba(209, 209, 209, 0.507);
  margin-left: 12px;
}

.img-fluid {
  margin: 30px 0 40px 0;
  width: 300px;
}

@media (max-width: 825px) {
  #shop.container-fluid {
    padding: 80px 15px;
  }

  .Card {
    padding: 43px 15px;
  }

  .Card h1 {
    font-size: 22px;
    line-height: 24px;
  }

  .Card h5 {
    font-size: 18px;
    line-height: 22px;
  }

  .title::after {
    bottom: -18px;
  }

  .Card p {
    font-size: 16px;
    line-height: 20px;
  }

  .btn-sc {
    font-size: 14px;
  }
}
</style>
