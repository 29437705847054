<template>
  <div id="main">
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="container my-5" style="text-align: center;">
            <h1><strong>{{locale[lang].MENTIONS.CONTENT[0].TITLE}}</strong></h1>
          </div>
          <div class="container" style="text-align: justify;">
            <p>{{ locale[lang].MENTIONS.CONTENT[0].TEXT }}</p><br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[1].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[1].TEXT[0]}}<br>
              {{locale[lang].MENTIONS.CONTENT[1].TEXT[1]}}<br>
              {{locale[lang].MENTIONS.CONTENT[1].TEXT[2]}}<br>
              {{locale[lang].MENTIONS.SITE_WEB}} <a :href="locale[lang].MENTIONS.LINK.LINK">{{locale[lang].MENTIONS.LINK.TEXT}}</a>
            </p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[2].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[2].TEXT[0]}}<br>
              {{locale[lang].MENTIONS.CONTENT[2].TEXT[1]}}<br>
              {{locale[lang].MENTIONS.SITE_WEB}} www.1and1.fr <br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[3].TITLE}}</span></b><br>
              Shirley Beleno Barrios<br>
              <a href="https://www.linkedin.com/in/shirley-beleno-barrios/">Linkedin</a></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[4].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[4].TEXT[0]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[4].TEXT[1]}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[5].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[5].TEXT[0]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[5].TEXT[1]}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[6].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[6].TEXT}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[7].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[7].TEXT[0]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[7].TEXT[1]}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[8].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[8].TEXT}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[9].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[9].TEXT[0]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[9].TEXT[1]}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[10].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[10].TEXT[0]}} <a :href="locale[lang].MENTIONS.LINK.LINK">{{locale[lang].MENTIONS.LINK.TEXT}}</a>.
              {{locale[lang].MENTIONS.CONTENT[9].TEXT[1]}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[11].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[11].TEXT}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[12].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[12].TEXT[0]}} <a :href="locale[lang].MENTIONS.LINK.LINK">{{locale[lang].MENTIONS.LINK.TEXT}}</a>
              {{locale[lang].MENTIONS.CONTENT[12].TEXT[1]}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[13].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[13].TEXT[0]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[13].TEXT[1]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[13].TEXT[2]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[13].TEXT[3]}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[14].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[14].TEXT[0]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[14].TEXT[1]}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[15].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[15].TEXT}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[16].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[16].TEXT[0]}} <a :href="locale[lang].MENTIONS.LINK.LINK">{{locale[lang].MENTIONS.LINK.TEXT}}</a><br><br>
              {{locale[lang].MENTIONS.CONTENT[16].TEXT[1]}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[17].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[17].TEXT[0]}} <a :href="locale[lang].SHOP.LINK">{{locale[lang].SHOP.LINK}}</a><br><br>
              <i><u>{{locale[lang].MENTIONS.CONTENT[17].TEXT[1]}}</u></i><br><br>
              {{locale[lang].MENTIONS.CONTENT[17].TEXT[2]}}<br><br>
              <i><u>{{locale[lang].MENTIONS.CONTENT[17].TEXT[3]}}</u></i><br><br>
              {{locale[lang].MENTIONS.CONTENT[17].TEXT[4]}}<br><br>
              <i><u>{{locale[lang].MENTIONS.CONTENT[17].TEXT[5]}}</u></i><br><br>
              {{locale[lang].MENTIONS.CONTENT[17].TEXT[6]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[17].TEXT[7]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[17].TEXT[8]}}<br><br>
              <i><u>{{locale[lang].MENTIONS.CONTENT[17].TEXT[9]}}</u></i><br><br>
              {{locale[lang].MENTIONS.CONTENT[17].TEXT[10]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[17].TEXT[11]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[17].TEXT[12]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[17].TEXT[13]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[17].TEXT[14]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[17].TEXT[15]}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[18].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[18].TEXT}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[19].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[19].TEXT[0]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[19].TEXT[1]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[19].TEXT[2]}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[20].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[20].TEXT}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[21].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[21].TEXT}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[22].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[22].TEXT}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[23].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[23].TEXT}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[24].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[24].TEXT[0]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[24].TEXT[1]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[24].TEXT[2]}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[25].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[25].TEXT[0]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[25].TEXT[1]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[25].TEXT[2]}}<br></p>
            <br>

            <p><b><span style="text-transform: uppercase;">{{locale[lang].MENTIONS.CONTENT[26].TITLE}}</span></b><br>
              {{locale[lang].MENTIONS.CONTENT[26].TEXT[0]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[26].TEXT[1]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[26].TEXT[2]}}<br><br>
              {{locale[lang].MENTIONS.CONTENT[26].TEXT[3]}}<br>
              {{locale[lang].MENTIONS.CONTENT[26].TEXT[4]}}<br>
              {{locale[lang].MENTIONS.CONTENT[26].TEXT[5]}}<br></p>
            <br>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import NavBar from "@/components/essential/NavBar.vue";
  export default {
    components: {
      NavBar
    }
  }
</script>

<style scoped>
  #nav-container {
    background-image: none;
    height: auto;
  }

  #nav-container>>>#logo {
    display: none;
  }

  .container, .container-fluid {
    background-color: transparent !important;
  }

  #main {
    padding: 0;
    background-image: url("../assets/Images/Blog/Enmascarar grupo 134.png"),
      url("../assets/Images/Background/Grupo 736.png"),
      url("../assets/Images/Background/Trazado 1903.png"),
      url("../assets/Images/Background/Grupo 685.png");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 100% 65%, 50% 100%, 0% 35%, 92% 10%;
    padding-bottom: 10%;
  }

  * {
    color: #0f1010;

  }

  p {
    font-size: 1.15rem;
    font-weight: 500;
  }

  a {
    color: #ff0000;
  }

  h1 {
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
  }

  @media screen and (max-width: 991px) {
    h1 {
      font-size: 2.5rem;
    }

    #main {
      background-size: 40%, 60%, 40%, 40%;
    }
  }
</style>